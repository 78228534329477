<template>
    <div class="loginBg">
        <div id="main">
            <div class="container">
                <div class="title wow animated fadeInDown">{{$t('login.loginTitle')}}</div>

                <div class="form_box wow animated fadeIn">
                    <div class="inp_label">{{$t('common.userName')}}</div>
                    <input v-model="account" class="inp" type="text" data-index="username"
                        :placeholder="$t('tip.usernameEmpty')" />

                    <div class="inp_label">
                        {{$t('common.pwd')}}
                        <span class="gradient g_btn" @click="jump('/forget')">{{$t('login.forget')}}</span>
                    </div>

                    <input v-model="password" class="inp" type="password" data-index="password" @keyup.enter="login"
                        :placeholder="$t('tip.pwdEmpty1')" />

                    <div class="fd">
                        <label for="readme">
                            <input v-model="checked" type="checkbox" name="readme" id="readme"
                                class="checkbox">{{$t('login.agree')}}
                        </label>
                        <router-link class="gradient" to="/agreement">《{{$t('common.agreement')}}》</router-link>
                    </div>

                    <el-button @click="login" class="btn login" :loading="logining">{{$t('common.login')}}</el-button>

                    <router-link to="/register" class="btn btn-o">
                        <span class="gradient">{{$t('login.toRegister')}}</span>
                    </router-link>
                </div>
                <div class="login_footer wow animated fadeInUp">
                    <p><span>System version {{version}} </span> @ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                </div>
            </div>
            <div class="icon">
                <img src="@/assets/images/official/login/icon.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
    import { Message } from 'element-ui'
    import { WOW } from 'wowjs'
    import { mapGetters } from 'vuex';
    export default {
        data() {
            return {
                account: '',
                password: '',
                checked: true,
                logining: false,
            }
        },
        computed: {
            ...mapGetters(['version'])
        },
        created() {
            // 初始化安全登录的账户、邮箱
            this.$store.commit('user/SET_SAFE_ACCOUNT', '')
            this.$store.commit('user/SET_SAFE_EMAIL', '')
        },
        mounted() {
            this.$nextTick(() => {
                this.wow();
            })
        },
        methods: {
            wow() {
                if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                    var wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                        mobile: true,
                        live: true
                    });
                    this.$nextTick(() => {
                        wow.init();
                    });
                };
            },
            jump(path) {
                this.$router.push({
                    path
                })
            },
            login() {
                if (!this.logining) {
                    // console.log('登录')
                    if (!this.account) {
                        Message({
                            message: this.$t('tip.usernameEmpty') + '！',
                            type: 'error',
                            duration: 3 * 1000
                        });
                    } else if (!this.password) {
                        Message({
                            message: this.$t('tip.pwdEmpty1') + '！',
                            type: 'error',
                            duration: 3 * 1000
                        });
                    } else if (!this.checked) {
                        Message({
                            message: this.$t('tip.check') + '！',
                            type: 'error',
                            duration: 3 * 1000
                        });
                    } else {
                        this.logining = true;
                        this.$store.commit('user/SET_SAFE_ACCOUNT', this.account)
                        this.$store.dispatch('user/login', {
                            account: this.account,
                            password: this.password,
                        }).then(res => {
                            this.logining = false;
                            if (this.$route.query.redirect) {
                                this.$router.push({
                                    path: this.$route.query.redirect
                                })
                            } else {
                                this.$router.push({
                                    path: '/stocks'
                                })
                            }
                        }).catch(() => {
                            this.logining = false;
                        })
                    }
                }
            },
        },
    }
</script>
<style scoped="scoped" lang="stylus">
    @import './login.styl';
    @import '~@/assets/style/frames.styl';
</style>